<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <h3 class="mt-1">
            Others Have Authorized
          </h3>
        </b-col>
        <b-col class="text-right">
          <b-button
            variant="outline-primary"
            class="m-1"
          >
            Add Authorization
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <company-authorizations-table :table-data="data.othersHaveAuthorized" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="mt-1">
            I Have Authorized
          </h3>
        </b-col>
        <b-col class="text-right">
          <b-button
            variant="outline-primary"
            class="m-1"
          >
            Add authorization
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <company-authorizations-table :table-data="data.iHaveAuthorized" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import CompanyAuthorizationsTable from '@/views/authorizations/CompanyAuthorizationsTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CompanyAuthorizationsTable,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/v1/authorizations')
      .then(response => {
        this.data = response.data
      })
  },
}
</script>

<style>

</style>
